import { lazy } from "react-router-guard";
import { checkAuth, checkResolve } from "./guards";

export default [
  {
    path: "/test",
    redirect: "/user"
  },
  {
    path: "/",
    component: lazy(() => import("./App")),
    routes: [
      {
        path: "/",
        exact: true,
        component: lazy(() => import("./Page/Home"))
      },
      {
        path: "/product/:productId/:sellerId",
        component: lazy(() => import("./Page/ProductDetail"))
      },
      {
        path: "/buy/:productId",
        component: lazy(() => import("./Page/BuyFlow"))
      },
      {
        path: "/order/:orderNumber",
        component: lazy(() => import("./Page/OrderSuccess"))
      },
      {
        path: "/history",
        component: lazy(() => import("./Page/History"))
      },
      {
        path: "/terms",
        component: lazy(() => import("./Page/Terms"))
      },
      {
        path: "/privacy",
        component: lazy(() => import("./Page/Privacy"))
      },
      {
        path: "/unauthorized",
        component: lazy(() => import("./Page/403"))
      },
      {
        path: "/error",
        component: lazy(() => import("./Page/403"))
      },
      {
        path: "*",
        component: lazy(() => import("./Page/NotFound"))
      },

    ]
  }
];
