import utils from "./utils";

var request = require('superagent-use')(require('superagent'));

request.use((req) => {
    req.header['device-Id'] = utils.getDeviceId();
    //req.header['client-id'] = 'dev';
    if(utils.getAuthorization() && utils.getAuthorization() != null){
        req.header['Authorization'] = utils.getAuthorization();
    }
    return req;
});
export default request;
