import utils from "./../helpers/utils";

import request from "./../helpers/superagentWrapper";

var iScroll = {
  products: {
    limit: 500,
    offset: 0,
    isLoading: false,
    featureProdsFetched: false,
  },
};

//let apiDomain = 'https://darwinboxstg.rewardskart.com/api';
//let apiDomain = "https://dev.rewardskart.com/api";
 //let apiDomain = 'https://meesho-stag.rewardskart.com/api';
 //let apiDomain = 'https://demo.rewardskart.com/api'
let apiDomain = "/api";

export const LOADING_APP = "LOADING_APP";
export const READ_PRODUCTS = "READ_PRODUCTS";
export const LOGIN_MODEL = "LOGIN_MODEL";
export const READ_PRODUCT_DETAIL = "READ_PRODUCT_DETAIL";
export const READ_USER = "READ_USER";
export const APPLY_GIFT_CODE = "APPLY_GIFT_CODE";
export const PLACE_ORDER = "PLACE_ORDER";
export const READ_ORDER_DETAIL = "READ_ORDER_DETAIL";
export const READ_ORDER_HISTORY = "READ_ORDER_HISTORY";
export const READ_ORDER_HISTORY_OLD = "READ_ORDER_HISTORY_OLD";
export const READ_ACCURAL_HISTORY = "READ_ACCURAL_HISTORY";
export const GIFT_MODEL = "GIFT_MODEL";
export const READ_BANEERS = "READ_BANEERS";
export const READ_CLIENT = "READ_CLIENT";
export const READ_CATEGORIES = "READ_CATEGORIES";
export const SAVED_FILTERS = "SAVED_FILTERS";
export const GENERATE_OTP = "GENERATE_OTP";
export const OTP_MODEL = "OTP_MODEL";
export const ERROR_MODEL = "ERROR_MODEL";
export const AVAIL_ALL = "AVAIL_ALL";

export const showlogin = function (open) {
  return (dispatch, getState) => {
    dispatch({
      type: LOGIN_MODEL,
      openLogin: open,
    });
  };
};
export const showGiftCode = function (open) {
  return (dispatch, getState) => {
    dispatch({
      type: GIFT_MODEL,
      openGift: open,
    });
  };
};
const handleGeneratorError = function (dispatch, res, isUser) {
  if (res && res.statusCode == "401" && !isUser) {
    dispatch({
      type: LOGIN_MODEL,
      openLogin: true,
    });
  } else if (res && res.statusCode == "302") {
    //utils.setCookie('device-id',utils.getDeviceId(),'30')
    if (res.text) {
      window.location.href =
        JSON.parse(res.text).redirectUrl + "?RelayState=" + utils.getDeviceId();
    }
  }
};
export const userLogin = function (data, callback) {
  return (dispatch, getState) => {
    return request
      .post(`${apiDomain}/users/v1/login`)
      .send(data)
      .end(function (err, res) {
        //localStorage.setItem('Authorization','Bearer '+res.body.response.accessToken)
        if (!err && res.body.status == "Success" && res.body.response) {
          // dispatch({
          //   type: USER_LOGIN,
          //   authenticate: res.body.response,
          // });
          localStorage.setItem(
            "Authorization",
            "Bearer " + res.body.response.accessToken
          );
          callback && callback();
        }
      });
  };
};
export const userLoginViaUUID = function (data, callback) {
  return (dispatch, getState) => {
    return request
      .post(`${apiDomain}/users/v1/no-login-token`)
      .send(data)
      .end(function (err, res) {
        //localStorage.setItem('Authorization','Bearer '+res.body.response.accessToken)
        if (!err && res.body.status == "Success" && res.body.response) {
          // dispatch({
          //   type: USER_LOGIN,
          //   authenticate: res.body.response,
          // });
          localStorage.setItem(
            "Authorization",
            "Bearer " + res.body.response.accessToken
          );
          callback && callback();
        }
      });
  };
};
export const logout = function () {
  return (dispatch, getState) => {
    localStorage.setItem("Authorization", "");
    dispatch({
      type: READ_USER,
      userDetail: null,
    });
  };
};
export const readUser = function () {
  return (dispatch, getState) => {
    return request
      .get(`${apiDomain}/users/profile/detail`)
      .end(function (err, res) {
        if (!err && res.body.status == "Success" && res.body.response) {
          dispatch({
            type: READ_USER,
            userDetail: res.body.response,
          });
        } else {
          handleGeneratorError(dispatch, res, true);
        }
      });
  };
};

export const readProducts = function () {
  return (dispatch, getState) => {
    return request
      .post(`${apiDomain}/v1/products/list`)
      .send({
        productName: getState().searchFilters.productName
          ? getState().searchFilters.productName
          : undefined,
        categoryIds: getState().searchFilters.categoryIds,
        productPrice: getState().searchFilters.inPointsActive
          ? getState().userDetail
            ? getState().userDetail.balance
            : 0
          : undefined,
        listPage: {
          page: 0,
          size: 100,
        },
        listOrders: [
          {
            direction: getState().searchFilters.selectedSort,
            property: getState().searchFilters.selectedSort
              ? "clientPrice"
              : "rank",
          },
        ],
        outOfStock: true,
      })
      .end(function (err, res) {
        if (!err && res.body.status == "Success" && res.body.products) {
          dispatch({
            type: READ_PRODUCTS,
            products: res.body.products,
          });
        } else {
        }
      });
  };
};
export const readProductDetail = function (id, seller) {
  return (dispatch, getState) => {
    return request
      .post(`${apiDomain}/v1/products/detail`)
      .send({
        productDetailRequests: [
          {
            productId: id,
            sellerId: seller,
          },
        ],
      })
      .end(function (err, res) {
        if (!err && res.body.status == "Success") {
          dispatch({
            type: READ_PRODUCT_DETAIL,
            productDetails: res.body.products[0],
          });
        } else {
        }
      });
  };
};
export const applyGiftCode = function (giftCode) {
  return (dispatch, getState) => {
    return request
      .post(`${apiDomain}/gift-codes/apply`)
      .send({ giftCode: giftCode })
      .end(function (err, res) {
        if (!err && res.body.status == "Success" && res.body.response) {
          dispatch({
            type: APPLY_GIFT_CODE,
            giftCodeApplied: res.body.response,
          });
        } else if (!err && res.body.status == "Failure") {
          dispatch({
            type: APPLY_GIFT_CODE,
            giftCodeApplied: {
              error: res.body.message,
            },
          });
        } else {
          handleGeneratorError(dispatch, res);
        }
      });
  };
};
export const clearGiftCode = function () {
  return (dispatch, getState) => {
    dispatch({
      type: APPLY_GIFT_CODE,
      giftCodeApplied: "",
    });
  };
};
export const placeOrder = function (data, callback, failureCallback) {
  return (dispatch, getState) => {
    return request
      .post(`${apiDomain}/v1/orders`)
      .send(data)
      .end(function (err, res) {
        if (!err && res.body.status == "Success") {
          dispatch({
            type: PLACE_ORDER,
            placedOrder: res.body,
          });
          callback && callback(res.body);
        } else if (!err && res.body.status == "Failure") {
          failureCallback && failureCallback(res.body.error.message);
        } else {
          handleGeneratorError(dispatch, res);
        }
      });
  };
};
export const clearOrder = function () {
  return (dispatch, getState) => {
    dispatch({
      type: PLACE_ORDER,
      placedOrder: "",
    });
  };
};
export const readOrderDetail = function (orderNo) {
  return (dispatch, getState) => {
    return request
      .get(`${apiDomain}/orders/${orderNo}`)
      .end(function (err, res) {
        if (!err && res.body.status == "Success" && res.body.response) {
          dispatch({
            type: READ_ORDER_DETAIL,
            orderDetail: res.body.response,
          });
        } else {
          handleGeneratorError(dispatch, res);
        }
      });
  };
};
export const readRedemtionHistory = function (orderNo) {
  return (dispatch, getState) => {
    return request
      .post(`${apiDomain}/v1/orders/history`)
      .send({
        listPage: {
          page: 0,
          size: 50,
        },
      })
      .end(function (err, res) {
        if (!err && res.body.status == "Success") {
          dispatch({
            type: READ_ORDER_HISTORY,
            redemptionHistory: res.body.orderLineItems,
          });
        } else {
          handleGeneratorError(dispatch, res);
        }
      });
  };
};
export const requestRedemtionHistory = function (emailId,callback) {
  return (dispatch, getState) => {
    return request
      .post(`${apiDomain}/v1/orders/send-order-history`)
      .send({
        listPage: {
          page: 0,
          size: 50,
        },
        emailAddress:emailId
      })
      .end(function (err, res) {
        if (!err && res.body.status == "Success"){
            callback && callback('Download confirmed! Your offers are en route to your email.',true)
        }else{
          let message = res.body.message || 'Something went wrong'
          callback && callback(message,false)

        }
        
      });
  };
};
export const readRedemtionHistoryOld = function (orderNo) {
  return (dispatch, getState) => {
    return request
      .get(`${apiDomain}/orders/history?page=0&size=30`)
      .end(function (err, res) {
        if (!err && res.body.status == "Success" && res.body.response) {
          dispatch({
            type: READ_ORDER_HISTORY_OLD,
            redemptionHistoryOld: res.body.response.data,
          });
        } else {
          handleGeneratorError(dispatch, res);
        }
      });
  };
};
export const readAccrualHistory = function (orderNo) {
  return (dispatch, getState) => {
    return request
      .get(`${apiDomain}//users/accruals/history?page=0&size=30`)
      .end(function (err, res) {
        if (!err && res.body.status == "Success" && res.body.response) {
          dispatch({
            type: READ_ACCURAL_HISTORY,
            accrualHistory: res.body.response.data,
          });
        } else {
          handleGeneratorError(dispatch, res);
        }
      });
  };
};
export const readBanners = function (data) {
  return (dispatch, getState) => {
    return request
      .get(`${apiDomain}/banners/fetch`)
      .query(data)
      .end(function (err, res) {
        if (!err && res.body.status == "Success" && res.body.response) {
          dispatch({
            type: READ_BANEERS,
            banners: res.body.response.data,
          });
        } else {
          handleGeneratorError(dispatch, res);
        }
      });
  };
};
export const readClient = function (callback) {
  return (dispatch, getState) => {
    return request.get(`${apiDomain}/clients/detail`).end(function (err, res) {
      if (!err && res.body.status == "Success" && res.body.response) {
        dispatch({
          type: READ_CLIENT,
          clientDetail: res.body.response,
        });
        callback && callback(res.body.response);
      } else {
        handleGeneratorError(dispatch, res);
      }
    });
  };
};

export const readCategories = function () {
  return (dispatch, getState) => {
    return request
      .post(`${apiDomain}/categories/list`)
      .send({
        listPage: {
          page: 0,
          size: 50,
        },
        listOrders: [
          {
            direction: "ASC",
            property: "rank",
          },
        ],
      })
      .end(function (err, res) {
        if (!err && res.status == 200 && res.body.categories) {
          dispatch({
            type: READ_CATEGORIES,
            categories: res.body.categories,
          });
        } else {
          handleGeneratorError(dispatch, res);
        }
      });
  };
};

export const searchFilters = (data) => {
  return {
    type: SAVED_FILTERS,
    filters: data,
  };
};

export const generateOtp = function (emailId, mobileNumber, userLoginType) {
  return (dispatch, getState) => {
    return request
      .post(`${apiDomain}/users/generate/otp`)
      .send({ emailId, mobileNumber, userLoginType })
      .end(function (err, res) {
        if (!err && res.body.status == "Success" && res.body.generateOtp) {
          dispatch({
            type: GENERATE_OTP,
            generateOtp: res.body.generateOtp,
          });
        } else {
          handleGeneratorError(dispatch, res);
        }
      });
  };
};


export const showOtplogin = function (open, inputValue, generateOtpData) {
  return (dispatch, getState) => {
    dispatch({
      type: OTP_MODEL,
      openOtp: open,
      inputValue: inputValue,
      generateOtpData: generateOtpData,
    });
  };
};

export const otpLogin = function (data, callback) {
  return (dispatch, getState) => {
    return request
      .post(`${apiDomain}/users/login/otp`)
      .send(data)
      .end(function (err, res) {
        if (res.body.message) {
          showMaxerr(res.body.message)(dispatch);
        }
        // localStorage.setItem('Authorization','Bearer '+res.body.response.accessToken)
        if (!err && res.body.status == "Success" && res.body.response) {
          // dispatch({
          //   type: USER_LOGIN,
          //   authenticate: res.body.response,
          // });
          localStorage.setItem(
            "Authorization",
            "Bearer " + res.body.response.accessToken
          );
          callback && callback();
        }
      });
  };
};

export const showMaxerr = function (maxError) {
  return (dispatch, getState) => {
    dispatch({
      type: ERROR_MODEL,
      maxError: maxError,
    });
  };
};
export const availAllOffer = function () {
  return (dispatch, getState) => {
    return request
      .post(`${apiDomain}/v1/orders/avail-all`)
      .end(function (err, res) {
          console.log(res)
      });
  };
};

