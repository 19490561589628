
const utils = {
    create_UUID(){
        var dt = new Date().getTime();
        var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = (dt + Math.random()*16)%16 | 0;
            dt = Math.floor(dt/16);
            return (c=='x' ? r :(r&0x3|0x8)).toString(16);
        });
        return uuid;
    },
    getDeviceId(){
        if(localStorage.getItem('device-Id')){
            return localStorage.getItem('device-Id');
        }else{
            let deviceId = this.create_UUID();
            localStorage.setItem('device-Id',deviceId);
            return deviceId
        }
    },
    getAuthorization(){
        return localStorage.getItem('Authorization');
    },
    isUserLoggedIn(){
        if(localStorage.getItem('Authorization') && localStorage.getItem('Authorization') != null){
            return true
        }
        return false
    },
    logout(){
        localStorage.setItem('Authorization',"")
    },
    getUrlParameter(name) {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
        var results = regex.exec(window.location.search);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    },
    
    setCookie(cname, cvalue, exdays) {
          const d = new Date();
          d.setTime(d.getTime() + (exdays*24*60*60*1000));
          let expires = "expires="+ d.toUTCString();
          document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

}
export default utils