import * as ActionTypes from "../actions";

import { combineReducers } from "redux";
import { ERROR_MODEL } from "../actions";

const openLogin = (state = null, action) => {
  const { type, openLogin } = action;
  if (type === ActionTypes.LOGIN_MODEL) {
    if (typeof openLogin != "undefined") {
      return openLogin;
    }
  }
  return state;
};
const openGift = (state = null, action) => {
  const { type, openGift } = action;
  if (type === ActionTypes.GIFT_MODEL) {
    if (typeof openGift != "undefined") {
      return openGift;
    }
  }
  return state;
};

const isAppLoading = (state = null, action) => {
  const { type, loading, authTicket } = action;

  if (type === ActionTypes.LOADING_APP) {
    if (typeof loading != "undefined") {
      return loading;
    }
  }
  return state;
};

const userDetail = (state = null, action) => {
  const { type, userDetail } = action;
  if (type === ActionTypes.READ_USER) {
    if (typeof userDetail != "undefined") {
      return userDetail;
    }
  }
  return state;
};

const products = (state = null, action) => {
  const { type, products } = action;
  if (type === ActionTypes.READ_PRODUCTS) {
    if (typeof products != "undefined") {
      return products;
    }
  }
  return state;
};
const productDetails = (state = null, action) => {
  const { type, productDetails } = action;
  if (type === ActionTypes.READ_PRODUCT_DETAIL) {
    if (typeof productDetails != "undefined") {
      return productDetails;
    }
  }
  return state;
};
const giftCodeApplied = (state = null, action) => {
  const { type, giftCodeApplied } = action;
  if (type === ActionTypes.APPLY_GIFT_CODE) {
    if (typeof giftCodeApplied != "undefined") {
      return giftCodeApplied;
    }
  }
  return state;
};
const placedOrder = (state = null, action) => {
  const { type, placedOrder } = action;
  if (type === ActionTypes.PLACE_ORDER) {
    if (typeof placedOrder != "undefined") {
      return placedOrder;
    }
  }
  return state;
};
const orderDetail = (state = null, action) => {
  const { type, orderDetail } = action;
  if (type === ActionTypes.READ_ORDER_DETAIL) {
    if (typeof orderDetail != "undefined") {
      return orderDetail;
    }
  }
  return state;
};
const accrualHistory = (state = null, action) => {
  const { type, accrualHistory } = action;
  if (type === ActionTypes.READ_ACCURAL_HISTORY) {
    if (typeof accrualHistory != "undefined") {
      return accrualHistory;
    }
  }
  return state;
};
const redemptionHistory = (state = null, action) => {
  const { type, redemptionHistory } = action;
  if (type === ActionTypes.READ_ORDER_HISTORY) {
    if (typeof redemptionHistory != "undefined") {
      return redemptionHistory;
    }
  }
  return state;
};
const redemptionHistoryOld = (state = null, action) => {
  const { type, redemptionHistoryOld } = action;
  if (type === ActionTypes.READ_ORDER_HISTORY_OLD) {
    if (typeof redemptionHistoryOld != "undefined") {
      return redemptionHistoryOld;
    }
  }
  return state;
};
const clientDetail = (state = null, action) => {
  const { type, clientDetail } = action;
  if (type === ActionTypes.READ_CLIENT) {
    if (typeof clientDetail != "undefined") {
      return clientDetail;
    }
  }
  return state;
};
const banners = (state = null, action) => {
  const { type, banners } = action;
  if (type === ActionTypes.READ_BANEERS) {
    if (typeof banners != "undefined") {
      return banners;
    }
  }
  return state;
};

const categories = (state = null, action) => {
  const { type, categories } = action;
  if (type === ActionTypes.READ_CATEGORIES) {
    if (typeof categories != "undefined") {
      return categories;
    }
  }
  return state;
};

const searchFilters = (state = [], action) => {
  const { type, filters } = action;
  if (type === ActionTypes.SAVED_FILTERS) {
    if (typeof filters != "undefined") {
      return filters;
    }
  }
  return state;
};

const generateOtp = (state = null, action) => {
  const { type, generateOtp } = action;
  if (type === ActionTypes.GENERATE_OTP) {
    if (typeof generateOtp != "undefined") {
      return generateOtp;
    }
  }
  return state;
};

const openOtp = (state = false, action) => {
  const { type, openOtp, inputValue, generateOtpData } = action;
  if (type === ActionTypes.OTP_MODEL) {
    if (typeof openOtp != "undefined") {
      return {
        openOtp: openOtp,
        inputValue: inputValue,
        generateOtpData: generateOtpData,
      };
    }
  }
  return state;
};

const maxError = (state = null, action) => {
  const { type, maxError } = action;
  if (type === ActionTypes.ERROR_MODEL) {
    if (typeof maxError != "undefined") {
      return maxError;
    }
  }
  return state;
};

const appReducer = combineReducers({
  loading: isAppLoading,
  products: products,
  openLogin,
  productDetails,
  giftCodeApplied,
  placedOrder,
  orderDetail,
  redemptionHistory,
  accrualHistory,
  userDetail,
  openGift,
  clientDetail,
  banners,
  redemptionHistoryOld,
  categories,
  searchFilters,
  generateOtp,
  openOtp,
  maxError,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
