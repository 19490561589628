// if (process.env.NODE_ENV === 'production') {
// 	module.exports = require('./Root.prod')
// } else {
// 	module.exports = require('./Root.dev')
// }

import React from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import { RouterGuard,history} from "react-router-guard";
import config from "../config";

const Root = ({ store }) => (
	<Provider store={store}>
		<div>
			<RouterGuard config={config} history={history} />
		</div>
	</Provider>
)

Root.propTypes = {
	store: PropTypes.object.isRequired
}

export default Root

